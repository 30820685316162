export default {
  data: () => ({
    loading: false,
  }),
  methods: {
    formResponse(re) {
      if (re.data.status === 'OK') {
        this.$notify({
          title: 'Created',
          text: '',
          type: 'success',
        });
        this.$router.push({ path: this.back });
      }
    },
  },
  created() {
  },
};
