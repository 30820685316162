<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create Service
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/service/create" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
                <TextInput type="text" label="Path" v-model="item.path" :required="false" ></TextInput>
                <SelectInput label="Type" v-model="item.type" :options="[{label:'Standard',value:'Standard'},{label:'License',value:'License'}]" :required="false" ></SelectInput>
                <SearchSelectInput @item:removed="productRemoved" @item:selected="productSelected" :params="productSearchParams" :multiple="true" label="Product" v-model="item.products" :optiondisplay="productDisplay"></SearchSelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateService',
    mixins: [Create],
    data: () => ({
        back: '/services',
        item: {
            name: '',
            path: '',
            type: '',
            products: [],
        },
        productSearchParams: {
            'model': 'Product',
            'fields': ['name'],
            'action': '/search/',
        },
        action: '/service/',
    }),
    computed: {
        formData() {
            return {
                'service':this.item,
            }
        },
    },
    props: [],
    methods: {
        productDisplay(item) {
            return item.name;
        },
        productSelected(evt) {
            let index = this.item.products.findIndex((product)=>{return product.id == evt.item.id})
            if(index < 0) this.item.products.push(evt.item);
        },
        productRemoved(evt) {
            this.item.products.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
