import Axios from 'axios';
import Swal from 'sweetalert2';

export default {
  data: () => ({
    item: false,
    PAP: false
  }),
  watch: {
    $route(val) {
      if (val) {
        this.getItem();
      }
    },
  },
  methods: {
    getItem() {
      const that = this;
      const id = this.$route.params[this.parameter];
      const requestUrl = id ? `${that.action}${id}` : `${that.action}`;
      Axios.get(requestUrl).then((re) => {
        that.item = re.data.item;
        that.PAP = re.data.PAP;
        that.itemFetched();
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      });
    },
    formResponse(re) {
      if (re.data.status === 'OK') {
        this.$notify({
          title: 'Updated',
          text: '',
          type: 'success',
        });
      }
    },
    maybeDelete() {
      const that = this;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          that.deleteItem();
        }
      });
    },
    deleteItem() {
      this.$requests.get(`/domains/remove/${this.item.id}`, {
        success: this.itemDeleted,
      });
    },
    itemDeleted() {
      this.$notify({
        title: 'Success! Item Deleted',
        text: '',
        type: 'success',
      });
      this.$router.push({ path: this.back });
      return true;
    },
    itemFetched() {},
  },
  created() {
    this.getItem();
  },
};
