import Axios from 'axios';
import Vue from 'vue';

export default {
  data: () => ({
    search: '',
    search_timeout: false,
    items: {},
    pages: [],
    count: 0,
    loading: false,
  }),
  methods: {
    RemoveItem(item, index = false) {
      if (this.count) {
        if (!index) index = this.pages[this.page].findIndex((object) => object.id === item.id);
        Vue.delete(this.pages[this.page], index);
      } else {
        if (!index) index = this.items.findIndex((object) => object.id === item.id);
        this.items.splice(index, 1);
      }
    },
    ItemDeleted(evt) {
      const that = this;
      Axios.get(`${this.action}/remove/${evt.item.id}`).then((re) => {
        if (re.data.status === 'OK') {
          if (that.count) {
            Vue.delete(that.pages[that.page], evt.index);
          } else {
            that.items.splice(evt.index, 1);
          }
          that.$notify({
            title: 'Deleted',
            text: '',
            type: 'success',
          });
        } else {
          that.$notify({
            title: 'Not Deleted',
            text: re.data.message,
            type: 'warning',
          });
        }
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        } else {
          that.$notify({
            title: 'Whoops something went wrong.',
            text: err.message,
            type: 'error',
          });
        }
      });
    },
    maybeSearch() {
      console.error('maybeSearch');
      if (this.search_timeout) clearTimeout(this.search_timeout);
      const that = this;
      if (!that.search || !that.search.length) {
        that.items = [];
        that.getItems();
        return;
      }
      this.search_timeout = setTimeout(() => {
        that.doSearch();
      }, 1000);
    },
    doSearch() {
      const that = this;
      this.loading = true;
      Axios.post('/search', {
        search: that.search,
        params: that.searchParams,
      }).then((re) => {
        that.loading = false;
        that.items = re.data.items;
        if (re.data.count) {
          that.count = re.data.count;
          Vue.set(that.pages, that.page, re.data.items);
        } else {
          that.items = re.data.items;
        }
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      }).then(() => {
        that.OnGetItemsSearched();
      });
    },
    getItems() {
      const that = this;
      let { action } = this;
      let count = false;
      if (this.limit && typeof this.page !== 'undefined') {
        count = true;
        action = `${this.action}/${this.limit}/${this.page}`;
      }
      Axios.get(action).then((re) => {
        if (count) {
          that.count = re.data.count;
          Vue.set(that.pages, that.page, re.data.items);
        } else {
          that.items = re.data.items;
        }
      }).catch((err) => {
        console.error(err);
        if (err.response.status === 403) {
          that.$notify({
            title: 'You are not allowed to do this...',
            text: 'Get lost',
            type: 'error',
          });
        }
      }).then(() => {
        that.OnGetItemsCompleted();
      });
    },
    OnGetItemsCompleted() {},
    OnGetItemsSearched() {},
  },
  created() {
    this.getItems();
  },
};
